import styled from 'styled-components';
import type { TrialSignupFormFragment } from '../../../wp-generated/types';
import { SignupFormWrapper } from '../../Form/Signup';
import {
  chooseLightOrDarkTextColor,
  getColorForCheckmark,
  moduleBackgroundToCSS,
} from '../getModuleColors';
import { liBeforeStyles } from '../getPrimaryColorForCheckmark';

const ModuleContainer = styled.section<{ $background: string; $color: string }>`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }
  background: ${({ $background }) => $background};
  color: ${({ $color }) => $color};
  gap: 2rem;
  padding: 1.25rem;
  @media (min-width: 1080px) {
    padding: var(--spacing-2x-large) var(--site-padding);
  }
  h2 {
    font-size: 4.25rem;
    line-height: 5.25rem;
    font-weight: 700;
    letter-spacing: -0.2rem;
  }
`;

const Kicker = styled.p<{ $color?: string }>`
  color: ${({ $color }) => ($color ? $color : 'var(--color-primary)')};
  font-weight: var(--font-weight-extra-bold);
  letter-spacing: 0.06em;
  text-transform: uppercase;
  margin-bottom: var(--spacing-small);
`;

const Text = styled.div<{ $primaryColor?: string }>`
  max-width: 100%;
  font-size: var(--font-size-large);
  line-height: 25px;
  font-weight: normal;

  div {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-medium);
  }

  a {
    color: var(--link-color);
  }

  ul {
    padding: 1rem 0;
    margin: 0;
    display: block;
  }

  li {
    margin-bottom: var(--spacing-small);
    list-style: none;
    position: relative;
    padding-left: 30px;
    display: flex;
  }

  ${liBeforeStyles};

  @media (min-width: 1080px) {
    max-width: 785px;
  }
`;

const TrialSignupModule = ({ moduleData }: { moduleData: TrialSignupFormFragment }) => {
  const formData = {
    title: moduleData?.title ?? null,
    description: moduleData?.description ?? null,
    submitButton: moduleData?.submitButton ?? null,
    acceptTerms: moduleData?.acceptTerms ?? null,
    terms: moduleData?.terms ?? null,
    newsletterOptIn: moduleData?.newsletterOptIn ?? null,
    bgColor: moduleData?.bgColor ? moduleData.bgColor[0] : null,
  };

  return (
    <ModuleContainer
      $background={moduleBackgroundToCSS(moduleData?.bgColor)}
      $color={chooseLightOrDarkTextColor(moduleData?.bgColor)}
    >
      <div>
        <header>
          <Kicker $color={getColorForCheckmark(moduleData?.bgColor)}>{moduleData?.kicker}</Kicker>
          <h2>{moduleData?.title}</h2>
        </header>
        <Text
          $primaryColor={getColorForCheckmark(moduleData?.bgColor)}
          dangerouslySetInnerHTML={{
            __html: moduleData?.description || '',
          }}
        />
      </div>
      <SignupFormWrapper formData={formData} />
    </ModuleContainer>
  );
};

export default TrialSignupModule;
