import { Icons } from '@mnd-frontend/ui';
import styled, { css } from 'styled-components';
import { ctaClickHandler } from '../../../lib/tracker';
import { formatUrl } from '../../../utils/formatUrl';
import type { ComparisonTableFragment } from '../../../wp-generated/types';
import LinkButton from '../../LinkButton';
import { usePrimaryColor } from '../getPrimaryColorForCheckmark';

const Container = styled.section`
  margin: var(--section-padding) auto;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    display: none;
  }
`;

const ContentContainer = styled.div`
  width: var(--site-width);
`;

const ProductTitlesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
`;

const ProductTitle = styled.div`
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-x-large);
  padding: var(--spacing-small);
  text-align: center;
`;

const Groups = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
`;

const GroupTitle = styled.div`
  font-weight: var(--font-weight-semi-bold);
  font-size: var(--font-size-large);
  grid-column: span 4;
  padding: var(--spacing-small);
`;

const GroupRow = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));

  &:nth-child(even) {
    background-color: var(--color-lightgray);
  }
`;

const FeatureTitle = styled.div<{ $hasTooltip: boolean }>`
  font-weight: var(--font-weight-semi-bold);
  display: inline;

  ${({ $hasTooltip }) =>
    $hasTooltip &&
    css`
      text-decoration: underline;
      margin-right: var(--spacing-small);
    `};

  a {
    color: var(--link-color);
    text-decoration: underline;
    text-decoration-color: var(--text-color);
  }
`;

const InfoIcon = styled(Icons.CircleInfo)<{ $size: string }>`
  display: inline-block;
  vertical-align: middle;
`;

const Tooltip = styled.div`
  background: var(--color-deep-purple);
  color: var(--color-white);
  display: flex;
  justify-content: center;
  text-align: center;
  position: absolute;
  max-width: 280px;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  left: -10px;
  right: -10px;
  bottom: calc(100% + 10px);
  opacity: 0;
  pointer-events: none;
  transition: opacity var(--anim-speed) var(--easing);
  padding: var(--spacing-small);
  font-size: var(--font-size-small);

  &:after {
    content: '';
    left: 45%;
    position: absolute;
    top: 100%;
    border-style: solid;
    border-width: 0 7px 7px 7px;
    border-color: transparent transparent var(--color-deep-purple) transparent;
    transform: rotate(180deg);
  }
`;

const FeatureTitleAndIcon = styled.div`
  position: relative;
  padding: var(--spacing-small);
  width: 90%;

  &:hover {
    ${Tooltip} {
      opacity: 1;
    }
  }
`;

const FeatureTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const FeatureContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BottomMenu = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  overflow: visible;
  position: sticky;
  bottom: var(--spacing-medium);
`;

const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-column: span 3;
  width: 100%;
  z-index: 1;
  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.25);
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: var(--spacing-small);
  background-color: var(--color-white);
`;

const Button = styled(LinkButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 9px 18px;
  line-height: 18px;
`;

const InvisibleItem = styled.div`
  visibility: hidden;
`;

const CheckIcon = ({ $primaryColor }: { $primaryColor?: string }) => {
  return (
    <svg width="15" height="22" viewBox="0 0 1000 1000" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M976 154C1006 184 1006 234 976 266L398 846C368 876 318 876 286 846L24 582C-6 552-6 502 24 470 54 440 104 440 136 470L344 678 866 154C896 124 946 124 976 154Z"
        fill={$primaryColor || 'current-color'}
      />
    </svg>
  );
};

const ComparisonTable = ({ moduleData }: { moduleData: ComparisonTableFragment }) => {
  const containerRefAndPrimary = usePrimaryColor();
  return (
    <Container ref={containerRefAndPrimary.ref}>
      <ContentContainer>
        <ProductTitlesContainer>
          <div />
          <ProductTitle>{moduleData.products?.product1?.title}</ProductTitle>
          <ProductTitle>{moduleData.products?.product2?.title}</ProductTitle>
          <ProductTitle>{moduleData.products?.product3?.title}</ProductTitle>
        </ProductTitlesContainer>
        <Groups>
          {moduleData?.groups?.map((group, index) => (
            <div key={index}>
              <GroupTitle
                dangerouslySetInnerHTML={{
                  __html: group?.title || '',
                }}
              />
              {group?.features?.map(
                (feature, index) =>
                  feature && (
                    <GroupRow key={index}>
                      <FeatureTitleContainer>
                        <FeatureTitleAndIcon>
                          {feature.description && <Tooltip>{feature.description}</Tooltip>}
                          <FeatureTitle
                            dangerouslySetInnerHTML={{
                              __html: feature.title || '',
                            }}
                            $hasTooltip={!!feature.description}
                          />
                          {feature.description && <InfoIcon $size="xxs" />}
                        </FeatureTitleAndIcon>
                      </FeatureTitleContainer>
                      {feature.text?.text1 && feature.text?.text2 && feature.text?.text3 ? (
                        <>
                          <FeatureContent
                            dangerouslySetInnerHTML={{
                              __html: feature.text?.text1 || '',
                            }}
                          />
                          <FeatureContent
                            dangerouslySetInnerHTML={{
                              __html: feature.text?.text2 || '',
                            }}
                          />
                          <FeatureContent
                            dangerouslySetInnerHTML={{
                              __html: feature.text?.text3 || '',
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <FeatureContent>
                            {feature.trueFalse?.trueFalse1 && (
                              <CheckIcon $primaryColor={containerRefAndPrimary.color} />
                            )}
                          </FeatureContent>
                          <FeatureContent>
                            {feature.trueFalse?.trueFalse2 && (
                              <CheckIcon $primaryColor={containerRefAndPrimary.color} />
                            )}
                          </FeatureContent>
                          <FeatureContent>
                            {feature.trueFalse?.trueFalse3 && (
                              <CheckIcon $primaryColor={containerRefAndPrimary.color} />
                            )}
                          </FeatureContent>
                        </>
                      )}
                    </GroupRow>
                  ),
              )}
            </div>
          ))}
        </Groups>
        <BottomMenu>
          <InvisibleItem />
          <ButtonsContainer>
            <ButtonContainer>
              {moduleData.products?.product1?.link?.url ? (
                <Button
                  href={formatUrl(moduleData.products.product1.link.url)}
                  variant="primary"
                  onClick={ctaClickHandler('comparison_table')}
                  prefetch={false}
                >
                  {moduleData.products.product1.link.title}
                </Button>
              ) : (
                <InvisibleItem />
              )}
            </ButtonContainer>
            <ButtonContainer>
              {moduleData.products?.product2?.link?.url ? (
                <Button
                  href={formatUrl(moduleData.products.product2.link.url)}
                  variant="primary"
                  onClick={ctaClickHandler('comparison_table')}
                  prefetch={false}
                >
                  {moduleData.products.product2.link.title}
                </Button>
              ) : (
                <InvisibleItem />
              )}
            </ButtonContainer>
            <ButtonContainer>
              {moduleData.products?.product3?.link?.url ? (
                <Button
                  href={formatUrl(moduleData.products.product3.link.url)}
                  variant="primary"
                  onClick={ctaClickHandler('comparison_table')}
                  prefetch={false}
                >
                  {moduleData.products.product3.link.title}
                </Button>
              ) : (
                <InvisibleItem />
              )}
            </ButtonContainer>
          </ButtonsContainer>
        </BottomMenu>
      </ContentContainer>
    </Container>
  );
};

export default ComparisonTable;
