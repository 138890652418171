import { useMediaQuery } from '@mnd-frontend/hooks';
import styled, { css } from 'styled-components';
import { ctaClickHandler } from '../../../lib/tracker';
import { formatUrl } from '../../../utils/formatUrl';
import type { ReviewsFragment } from '../../../wp-generated/types';
import LinkButton from '../../LinkButton';
import { moduleBackgroundToCSS } from '../getModuleColors';

const Container = styled.section<{ $background: string }>`
  display: flex;
  flex-direction: column;
  position: relative;
  background: ${({ $background }) => $background};
  padding: var(--section-padding) var(--site-padding);
  align-items: center;
`;

const Title = styled.div`
  font-size: 1rem;
  font-weight: var(--font-weight-extra-bold);
  letter-spacing: 1px;
  line-height: 22px;
  text-transform: uppercase;
  margin-bottom: var(--spacing-large);
`;

const ReviewsContainer = styled.article<{ $numberOfReviews: number }>`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: var(--spacing-large);
  margin-bottom: 85px;

  ${({ $numberOfReviews }) =>
    $numberOfReviews > 1 &&
    css`
      @media (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
      }
    `};

  ${({ $numberOfReviews }) =>
    $numberOfReviews > 2 &&
    css`
      @media (min-width: 1080px) {
        grid-template-columns: repeat(3, 1fr);
      }
    `};
`;

const Reviewer = styled.div`
  display: flex;
  margin-bottom: var(--spacing-small);
  gap: 1rem;
  align-items: center;
`;

const Review = styled.div`
  background-color: var(--color-white);
  padding: var(--spacing-medium);
  border-radius: 40px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ReviewImage = styled.img`
  height: 64px;
  width: 64px;
  border-radius: 50%;
  object-fit: cover;
`;

const NameAndRating = styled.div`
  display: flex;
  flex-direction: column;
`;

const RatingAndDate = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem 1rem;
`;

const Name = styled.div`
  font-weight: var(--font-weight-bold);
  margin: 8px 0;
`;

const Rating = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3px;
`;

const DateCommponent = styled.div`
  display: inline;
  color: var(--color-gray);
`;

const ReviewTitle = styled.div`
  font-size: var(--font-size-x-large);
  font-weight: var(--font-weight-bold);
  letter-spacing: -0.5px;
  line-height: 29px;
  margin-bottom: 4px;
`;

const Description = styled.div`
  margin-bottom: var(--spacing-medium);
`;

const ExternalLinkSvg = styled.div`
  width: 15px;
  height: 15px;
  margin: 0 var(--spacing-x-small) var(--spacing-x-small);
  color: var(--link-color);
  transition: transform var(--anim-speed) var(--easing);
`;

const ReviewLinkAndIcon = styled.a`
  display: flex;
  align-items: center;

  &:hover {
    ${ExternalLinkSvg} {
      transform: translateX(2px);
    }
  }
`;

const ReviewLink = styled.div`
  color: var(--link-color);
  font-weight: var(--font-weight-semi-bold);
  bottom: 0;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Button = styled(LinkButton)`
  display: flex;
  justify-content: center;
  width: 100%;
  line-height: 20px;

  @media (min-width: 768px) {
    width: fit-content;
  }
`;

const FilledStar = () => {
  return (
    <svg width="21" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.88.49a.87.87 0 0 0-1.57 0L6.68 5.85l-5.93.87a.87.87 0 0 0-.49 1.49l4.29 4.18-1.02 5.89a.87.87 0 0 0 1.27.92l5.3-2.78 5.3 2.78a.87.87 0 0 0 1.26-.92l-1.01-5.89 4.3-4.18a.87.87 0 0 0-.48-1.49l-5.93-.87L10.88.49Z"
        fill="#FF4473"
      />
    </svg>
  );
};

const HalfFilledStar = () => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2991_1416)">
        <mask
          id="mask0_2991_1416"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="-2"
          width="11"
          height="23"
        >
          <rect y="-1.16138" width="10.11" height="21.6227" fill="#C4C4C4" />
        </mask>

        <g mask="url(#mask0_2991_1416)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.8832 0.485672C10.7354 0.187741 10.4313 -0.000505144 10.0987 1.01819e-06C9.76613 0.000507233 9.46263 0.189679 9.3157 0.488058L6.67705 5.84654L0.748896 6.71952C0.420075 6.76794 0.147148 6.99862 0.0446182 7.31478C-0.0579118 7.63093 0.0276896 7.97788 0.265509 8.21007L4.54848 12.3915L3.53372 18.2772C3.47717 18.6051 3.61175 18.9368 3.88086 19.1326C4.14998 19.3285 4.50694 19.3545 4.80164 19.1998L10.1 16.4187L15.3985 19.1998C15.6932 19.3545 16.0501 19.3285 16.3192 19.1326C16.5884 18.9368 16.7229 18.6051 16.6664 18.2772L15.6518 12.3928L19.955 8.21151C20.1936 7.97963 20.2799 7.63239 20.1777 7.31576C20.0754 6.99913 19.8023 6.768 19.4731 6.71952L13.5432 5.84629L10.8832 0.485672Z"
            fill="#FF4473"
          />

          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.8832 0.485672C10.7354 0.187741 10.4313 -0.000505144 10.0987 1.01819e-06C9.76613 0.000507233 9.46263 0.189679 9.3157 0.488058L6.67705 5.84654L0.748896 6.71952C0.420075 6.76794 0.147148 6.99862 0.0446182 7.31478C-0.0579118 7.63093 0.0276896 7.97788 0.265509 8.21007L4.54848 12.3915L3.53372 18.2772C3.47717 18.6051 3.61175 18.9368 3.88086 19.1326C4.14998 19.3285 4.50694 19.3545 4.80164 19.1998L10.1 16.4187L15.3985 19.1998C15.6932 19.3545 16.0501 19.3285 16.3192 19.1326C16.5884 18.9368 16.7229 18.6051 16.6664 18.2772L15.6518 12.3928L19.955 8.21151C20.1936 7.97963 20.2799 7.63239 20.1777 7.31576C20.0754 6.99913 19.8023 6.768 19.4731 6.71952L13.5432 5.84629L10.8832 0.485672Z"
            fill="#FF4473"
          />
        </g>

        <mask
          id="mask1_2991_1416"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="21"
          height="20"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.8832 0.485673C10.7354 0.187743 10.4313 -0.000504125 10.0987 1.01408e-06C9.76613 0.000508252 9.46263 0.18968 9.3157 0.488059L6.67705 5.84654L0.748896 6.71952C0.420075 6.76794 0.147148 6.99862 0.0446182 7.31478C-0.0579118 7.63094 0.0276896 7.97788 0.265509 8.21007L4.54848 12.3915L3.53372 18.2772C3.47717 18.6051 3.61175 18.9368 3.88086 19.1326C4.14998 19.3285 4.50694 19.3545 4.80164 19.1998L10.1 16.4187L15.3985 19.1998C15.6932 19.3545 16.0501 19.3285 16.3192 19.1326C16.5884 18.9368 16.7229 18.6051 16.6664 18.2772L15.6518 12.3928L19.955 8.21151C20.1936 7.97963 20.2799 7.63239 20.1777 7.31576C20.0754 6.99913 19.8023 6.768 19.4731 6.71952L13.5432 5.84629L10.8832 0.485673Z"
            fill="#FF4473"
          />
        </mask>
        <g mask="url(#mask1_2991_1416)">
          <rect x="10.1091" y="-5" width="14" height="31" fill="#C4C3D3" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2991_1416">
          <rect width="20.22" height="19.3" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const EmptyStar = () => {
  return (
    <svg width="21" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.88.49a.87.87 0 0 0-1.57 0L6.68 5.85l-5.93.87a.87.87 0 0 0-.49 1.49l4.29 4.18-1.02 5.89a.87.87 0 0 0 1.27.92l5.3-2.78 5.3 2.78a.87.87 0 0 0 1.26-.92l-1.01-5.89 4.3-4.18a.87.87 0 0 0-.48-1.49l-5.93-.87L10.88.49Z"
        fill="#C4C3D3"
      />
    </svg>
  );
};

const mapLangToLocale: Record<string, string> = {
  'se-sv': 'sv-SE',
  'de-de': 'de-DE',
  'no-no': 'no-NO',
  'dk-da': 'da-DK',
  en: 'en-GB',
};

function getCorrectDateFormat(currentLocale?: string, date?: string | null) {
  const correctlyFormattedLocale =
    (currentLocale && mapLangToLocale[currentLocale]) || mapLangToLocale.en;
  if (date) {
    return new Intl.DateTimeFormat(correctlyFormattedLocale, {
      day: 'numeric',
      month: 'short',
    })
      .format(new Date(date))
      .replaceAll('.', '');
  } else {
    return '';
  }
}

const Reviews = ({ moduleData, locale }: { moduleData: ReviewsFragment; locale?: string }) => {
  const isMobile = useMediaQuery('@media (max-width: 767px)');

  return (
    <Container $background={moduleBackgroundToCSS(moduleData.bgColor)}>
      <Title>{moduleData.title}</Title>
      <ReviewsContainer $numberOfReviews={moduleData.reviews?.length || 0}>
        {moduleData.reviews?.map((review, index) => {
          const rating = review?.rating || 0;
          const integerRating = Math.floor(rating);
          const hasHalfStar = rating % 1 !== 0;
          const emptyStars = 5 - Math.ceil(rating);
          return (
            <Review key={index}>
              <div>
                <Reviewer>
                  <ReviewImage
                    src={review?.image?.node.mediaItemUrl || ''}
                    alt=""
                    role="presentation"
                    loading="lazy"
                    title={review?.image?.node?.altText || undefined}
                  />
                  <NameAndRating>
                    <Name
                      dangerouslySetInnerHTML={{
                        __html: review?.name || '',
                      }}
                    />
                    <RatingAndDate>
                      <Rating>
                        {[...Array(integerRating)].map((elem, index) => (
                          <FilledStar key={index} />
                        ))}
                        {hasHalfStar && <HalfFilledStar />}
                        {[...Array(emptyStars)].map((elem, index) => (
                          <EmptyStar key={index} />
                        ))}
                      </Rating>
                      <DateCommponent>{getCorrectDateFormat(locale, review?.date)}</DateCommponent>
                    </RatingAndDate>
                  </NameAndRating>
                </Reviewer>
                <ReviewTitle
                  dangerouslySetInnerHTML={{
                    __html: review?.title || '',
                  }}
                />
                <Description
                  dangerouslySetInnerHTML={{
                    __html: review?.description || '',
                  }}
                />
              </div>
              {review?.link?.url && (
                <ReviewLinkAndIcon
                  href={formatUrl(review?.link?.url)}
                  target={review?.link?.target || ''}
                  onClick={ctaClickHandler('reviews')}
                >
                  <ReviewLink>{review?.link?.title}</ReviewLink>
                  <ExternalLinkSvg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 1000 1000"
                      strokeWidth="1.5"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fill="currentColor"
                        d="M582 68C582 32 612 2 648 2H936C942 0 950 0 956 2 966 4 976 10 984 18 996 32 1004 48 1002 66V352C1002 388 972 418 936 418 900 418 870 388 870 352V226L450 642C424 666 382 664 358 638 336 612 336 574 358 550L772 134H644C608 132 582 104 582 68ZM170 278C154 278 140 292 140 308V834C140 850 154 864 170 864H696C712 864 726 850 726 834V548C726 512 756 482 792 482 828 482 858 512 858 548L858 548V836C858 926 784 1000 694 1000H164C74 1000 0 926 0 836V308C0 218 74 144 164 144H452C488 144 518 174 518 210 518 246 488 276 452 276L170 278Z"
                      />
                    </svg>
                  </ExternalLinkSvg>
                </ReviewLinkAndIcon>
              )}
            </Review>
          );
        })}
      </ReviewsContainer>
      <ButtonsWrapper>
        {moduleData.links?.map(
          (link, index) =>
            link?.link?.url && (
              <Button
                variant="purple"
                key={index}
                href={formatUrl(link?.link?.url)}
                target={link?.link?.target || ''}
                fullWidth={isMobile}
                onClick={ctaClickHandler('reviews')}
                prefetch={false}
              >
                {link?.link?.title}
              </Button>
            ),
        )}
      </ButtonsWrapper>
    </Container>
  );
};

export default Reviews;
