import { ctaClickHandler } from '../../../lib/tracker';
import { formatUrl } from '../../../utils/formatUrl';
import type { PageFragment } from '../../../wp-generated/types';
import { HubspotForm } from '../../Form/HubspotForm';
import LinkButton from '../../LinkButton';
import {
  chooseButtonColorBasedOnBackground,
  chooseLightOrDarkTextColor,
  getColorForCheckmark,
  moduleBackgroundToCSS,
} from '../getModuleColors';
import { Container, Kicker, ModuleFooter, Title, Wysiwyg } from './CommonStyles';

const HeroForm = ({
  data,
  formTrackingName,
}: {
  data: NonNullable<PageFragment['hero']>['heroForm'];
  formTrackingName: string;
}) => {
  return (
    <Container
      $backgroundcolor={moduleBackgroundToCSS(data?.bgColor)}
      $color={chooseLightOrDarkTextColor(data?.bgColor)}
      $columns="2"
      $withForm
      data-type="form"
    >
      <div>
        {data?.kicker && (
          <Kicker $color={getColorForCheckmark(data?.bgColor)}>{data.kicker}</Kicker>
        )}
        <Title dangerouslySetInnerHTML={{ __html: data?.title ?? '' }} />
        <Wysiwyg
          dangerouslySetInnerHTML={{ __html: data?.description ?? '' }}
          $primaryColor={getColorForCheckmark(data?.bgColor)}
        />
      </div>
      <HubspotForm
        hsFormId={data?.form?.hubspotFormId}
        title={data?.form?.title}
        formTrackingName={formTrackingName}
        background={data?.bgColor ? data.bgColor[0] : null}
      />
      {data?.links && data?.links.length > 0 && (
        <ModuleFooter>
          {data?.links.map((link, i) => (
            <LinkButton
              key={link?.link?.url || i}
              href={formatUrl(link?.link?.url)}
              variant={chooseButtonColorBasedOnBackground(data?.bgColor)}
              onClick={ctaClickHandler('hero')}
              prefetch={false}
            >
              {link?.link?.title}
            </LinkButton>
          ))}
        </ModuleFooter>
      )}
    </Container>
  );
};

export default HeroForm;
