import styled from 'styled-components';
import { ctaClickHandler } from '../../../lib/tracker';
import { formatUrl } from '../../../utils/formatUrl';
import type { PageFragment } from '../../../wp-generated/types';
import LinkButton from '../../LinkButton';
import {
  chooseButtonColorBasedOnBackground,
  chooseLightOrDarkTextColor,
  getColorForCheckmark,
  moduleBackgroundToCSS,
} from '../getModuleColors';
import { Container, Kicker, ModuleFooter, Title, Wysiwyg } from './CommonStyles';

const IframeWrapper = styled.div`
  width: 100%;
  aspect-ratio: 8 / 6;
`;

const HeroCenter = ({ data }: { data: NonNullable<PageFragment['hero']>['heroCenter'] }) => {
  const sanitizedContent = data?.description
    ? data.description
        .replace(/<script[\s\S]*?>[\s\S]*?<\/script>/gi, '')
        .replace(/<iframe[\s\S]*?>[\s\S]*?<\/iframe>/gi, '')
        .replace(/<div id="guideflow-wrapper"[\s\S]*?<\/div>/gi, '')
    : '';
  const iframeSrc = data?.description?.match(/<iframe.*?src="(.*?)"/)?.[1];
  const iframeId = data?.description?.match(/<iframe.*?id="(.*?)"/)?.[1];

  return (
    <Container
      $backgroundcolor={moduleBackgroundToCSS(data?.bgColor)}
      $color={chooseLightOrDarkTextColor(data?.bgColor)}
      $center={true}
      data-type="center"
    >
      <header>
        {data?.kicker && <Kicker>{data.kicker}</Kicker>}
        <Title dangerouslySetInnerHTML={{ __html: data?.title ?? '' }} />
      </header>
      <Wysiwyg
        dangerouslySetInnerHTML={{
          __html: sanitizedContent || '',
        }}
        $primaryColor={getColorForCheckmark(data?.bgColor)}
      />
      {iframeSrc && (
        <IframeWrapper>
          <iframe
            id={iframeId}
            src={iframeSrc}
            loading="lazy"
            width="100%"
            height="100%"
            allow="picture-in-picture"
            sandbox="allow-scripts allow-same-origin"
            allowFullScreen
          />
        </IframeWrapper>
      )}

      {data?.links && data?.links.length > 0 && (
        <ModuleFooter>
          {data?.links.map((link, i) => (
            <LinkButton
              key={link?.link?.url || i}
              href={formatUrl(link?.link?.url)}
              variant={chooseButtonColorBasedOnBackground(data?.bgColor)}
              onClick={ctaClickHandler('hero')}
              prefetch={false}
            >
              {link?.link?.title}
            </LinkButton>
          ))}
        </ModuleFooter>
      )}
    </Container>
  );
};

export default HeroCenter;
