import React from 'react';
import styled from 'styled-components';
import { ctaClickHandler } from '../../../lib/tracker';
import { formatUrl } from '../../../utils/formatUrl';
import type { GlobalModulesFragment, GlobalTextBlockFragment } from '../../../wp-generated/types';
import { getModule } from '../../CmsPage';
import LinkButton from '../../LinkButton';
import { chooseLightOrDarkTextColor, moduleBackgroundToCSS } from '../getModuleColors';

const Container = styled.div<{ $textColor: string; $background: string }>`
  background-color: var(--color-deep-purple);
  color: ${({ $textColor }) => $textColor};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  z-index: 1;
  padding-top: clamp(6rem, 24vw, 9rem);
  padding-bottom: clamp(6rem, 24vw, 9rem);
  width: 100%;
  box-sizing: border-box;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 140%;
    height: 100%;
    background: ${({ $background }) => $background};
    border-radius: 0 0 50% 50%;
    z-index: 0;

    @media (max-width: 768px) {
      width: 200%;
    }
  }
`;

const Content = styled.section`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 4vw 0 4vw;
  min-width: 768px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    min-width: 0;
    width: 100%;
  }
`;

const Title = styled.h2`
  font-size: clamp(1.5rem, 7vw, 2.75rem);
  font-weight: var(--font-weight-bold);
  margin-bottom: var(--spacing-small);
  z-index: 1;
  letter-spacing: -0.03em;

  @media (min-width: 630px) {
    font-size: 44px;
    line-height: 53px;
    letter-spacing: -1px;
  }
`;

const Description = styled.div`
  margin-bottom: var(--spacing-medium);
  z-index: 1;
`;

const Button = styled(LinkButton)`
  padding: 20px var(--spacing-medium);
  line-height: 20px;
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-semi-bold);
  z-index: 1;
  display: flex;
  justify-content: center;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: var(--spacing-small);

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const GlobalTextBlock = ({ moduleData }: { moduleData: GlobalTextBlockFragment }) => {
  return (
    <Container
      $textColor={chooseLightOrDarkTextColor(moduleData.bgColor)}
      $background={moduleBackgroundToCSS(moduleData.bgColor)}
    >
      <Content>
        <Title>{moduleData.title}</Title>
        <Description
          dangerouslySetInnerHTML={{
            __html: moduleData.description || '',
          }}
        />
        <ButtonsWrapper>
          {moduleData.links?.map(
            (link, index) =>
              link?.link?.url && (
                <Button
                  key={index}
                  href={formatUrl(link.link.url)}
                  target={link?.link?.target || ''}
                  variant={index === 0 ? 'white' : 'transparentWhite'}
                  onClick={ctaClickHandler('text_block')}
                  prefetch={false}
                >
                  {link?.link?.title}
                </Button>
              ),
          )}
        </ButtonsWrapper>
      </Content>
    </Container>
  );
};

const GlobalModule = ({
  moduleData,
  locale,
}: {
  moduleData: GlobalModulesFragment;
  locale?: string;
}) => {
  return moduleData.data?.map((oneModule, index) => {
    if (oneModule?.__typename === 'ModulesFlexibleModulesTextBlockLayout') {
      return <GlobalTextBlock key={index} moduleData={oneModule} />;
    } else {
      return <React.Fragment key={index}>{getModule(oneModule, locale)}</React.Fragment>;
    }
  });
};

export default GlobalModule;
