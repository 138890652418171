import { ctaClickHandler } from '../../../lib/tracker';
import { formatUrl } from '../../../utils/formatUrl';
import type { PageFragment } from '../../../wp-generated/types';
import { SignupFormWrapper } from '../../Form/Signup';
import LinkButton from '../../LinkButton';
import {
  chooseButtonColorBasedOnBackground,
  chooseLightOrDarkTextColor,
  getColorForCheckmark,
  moduleBackgroundToCSS,
} from '../getModuleColors';
import { Container, Kicker, ModuleFooter, Title, Wysiwyg } from './CommonStyles';

const HeroTrial = ({ data }: { data: NonNullable<PageFragment['hero']>['heroTrial'] }) => {
  const formData = {
    title: data?.form?.title ?? null,
    description: data?.form?.description ?? null,
    submitButton: data?.form?.submitButton ?? null,
    acceptTerms: data?.form?.acceptTerms ?? null,
    terms: data?.form?.terms ?? null,
    newsletterOptIn: data?.form?.newsletterOptIn ?? null,
    bgColor: (data?.bgColor && data.bgColor[0]) ?? null,
  };
  return (
    <Container
      $backgroundcolor={moduleBackgroundToCSS(data?.bgColor)}
      $color={chooseLightOrDarkTextColor(data?.bgColor)}
      $columns="2"
      $bgimage={data?.image?.node?.mediaItemUrl || undefined}
      $withForm
      data-type="trial"
    >
      <div>
        {data?.kicker && (
          <Kicker $color={getColorForCheckmark(data?.bgColor || ['white'])}>{data.kicker}</Kicker>
        )}
        <Title dangerouslySetInnerHTML={{ __html: data?.title ?? '' }} />
        <Wysiwyg
          dangerouslySetInnerHTML={{ __html: data?.description || '' }}
          $primaryColor={getColorForCheckmark(data?.bgColor || ['white'])}
        />
      </div>
      <SignupFormWrapper formData={formData} />
      {data?.links && data?.links.length > 0 && (
        <ModuleFooter>
          {data?.links.map((link, i) => (
            <LinkButton
              key={link?.link?.url || i}
              href={formatUrl(link?.link?.url)}
              variant={chooseButtonColorBasedOnBackground(data?.bgColor)}
              onClick={ctaClickHandler('hero')}
              prefetch={false}
            >
              {link?.link?.title}
            </LinkButton>
          ))}
        </ModuleFooter>
      )}
    </Container>
  );
};

export default HeroTrial;
