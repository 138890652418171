import styled, { css } from 'styled-components';
import type { PricingPlansFragment } from '../../../wp-generated/types';
import { chooseLightOrDarkTextColor, moduleBackgroundToCSS } from '../getModuleColors';
import { liBeforeStyles, usePrimaryColor } from '../getPrimaryColorForCheckmark';

const Container = styled.section<{ $background: string; $textColor: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 80px;
  margin: 0 auto;
  padding: var(--section-padding) var(--site-padding);
  color: ${({ $textColor }) => $textColor};
  background: ${({ $background }) => $background};
`;

const ContentContainer = styled.div<{ $numberOfPlans: number }>`
  width: var(--site-width);
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  row-gap: var(--spacing-large);
  column-gap: 26px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (min-width: 1080px) {
    ${({ $numberOfPlans }) =>
      $numberOfPlans > 2
        ? css`
            grid-template-columns: repeat(3, minmax(0, 1fr));
          `
        : css`
            grid-template-columns: repeat(2, minmax(0, calc((100vw - var(--site-padding)) / 3)));
            justify-content: center;
          `}
  }
`;

const dividingLine = css`
  background: currentColor;
  content: '';
  display: block;
  height: 100%;
  opacity: 0.15;
  width: 2px;
  margin-left: 26px;
`;

const PricingPlanContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (min-width: 768px) and (max-width: 1080px) {
    &:not(:nth-child(2n)):after {
      ${dividingLine}
    }
  }
  @media (min-width: 1080px) {
    &:not(:last-child):after {
      ${dividingLine}
    }
  }
`;

const PricingPlan = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Title = styled.div`
  font-size: var(--font-size-x-large);
  font-weight: var(--font-weight-bold);
  margin-bottom: var(--spacing-small);
`;

const ModuleTitle = styled.h2`
  font-size: var(--text-kicker-size, var(--body-font-size));
  font-style: var(--body-font-style, normal);
  font-weight: 900;
  letter-spacing: 0.06em;
  line-height: var(--body-line-height, 1.6);
  text-transform: var(--body-text-transform, none);
  text-transform: uppercase;
`;

const Description = styled.div<{ $primaryColor: string }>`
  font-size: var(--font-size-large);

  a {
    color: var(--link-color);
  }

  ul {
    padding: 0;
    margin: var(--spacing-medium) 0;
    display: block;
  }

  li {
    margin-bottom: var(--spacing-small);
    list-style: none;
    position: relative;
    padding-left: 30px;
    font-size: 15px;
  }

  ${liBeforeStyles}
`;

const PricingPlans = ({ moduleData }: { moduleData: PricingPlansFragment }) => {
  const containerRefAndPrimaryColor = usePrimaryColor();
  const bgColor = moduleBackgroundToCSS(moduleData.bgColor) || 'transparent';
  const textColor = chooseLightOrDarkTextColor(bgColor);
  return (
    <Container ref={containerRefAndPrimaryColor.ref} $background={bgColor} $textColor={textColor}>
      {moduleData.title ? <ModuleTitle>{moduleData.title}</ModuleTitle> : null}
      <ContentContainer $numberOfPlans={moduleData?.pricingPlans?.length ?? 0}>
        {moduleData?.pricingPlans?.map((pricingPlan, index) => (
          <PricingPlanContainer key={index}>
            <PricingPlan>
              <Title>{pricingPlan?.title}</Title>
              <Description
                dangerouslySetInnerHTML={{ __html: pricingPlan?.description || '' }}
                $primaryColor={containerRefAndPrimaryColor.color}
              />
            </PricingPlan>
          </PricingPlanContainer>
        ))}
      </ContentContainer>
    </Container>
  );
};

export default PricingPlans;
