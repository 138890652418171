import { type ReactNode, useEffect, useId, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { track } from '../../lib/tracker';
import Button from '../Button';

const ThumbnailContainer = styled.div<{ $portrait?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  aspect-ratio: ${({ $portrait }) => ($portrait ? '9 / 16' : '16 / 9')};
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Thumbnail = styled.img<{ $portrait?: boolean }>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: ${({ $portrait }) => ($portrait ? '9 / 16' : '16 / 9')};
  cursor: pointer;
  filter: blur(0);
  transform: scale(1.1);
`;

const PlayButton = styled(Button)`
  padding: 0;
  font-size: 17px;
  width: fit-content;
  position: absolute;
  padding: 20px var(--spacing-medium);
  bottom: clamp(var(--spacing-medium), 15%, var(--spacing-large));
`;

const StyledVideo = styled.div<{ $portrait?: boolean }>`
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  div {
    aspect-ratio: ${({ $portrait }) => ($portrait ? '9 / 16' : '16 / 9')};
    height: 100%;

    iframe {
      display: flex;
      max-width: 100%;
      max-height: 100%;
      aspect-ratio: ${({ $portrait }) => ($portrait ? '9 / 16' : '16 / 9')};
      ${({ $portrait }) =>
        $portrait &&
        css`
          height: auto;
          width: 100%;
        `};
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
`;

interface thumbnailData {
  title: string | null;
  author_name: string | null;
  author_url: string | null;
  type: string | null;
  height: number | null;
  width: number | null;
  version: string | null;
  provider_name: string | null;
  provider_url: string | null;
  thumbnail_height: number | null;
  thumbnail_width: number | null;
  thumbnail_url: string | null;
}

const parseOEmbed = (htmlString: string) => {
  const div = document.createElement('div');
  div.innerHTML = htmlString;
  const iframe = div.querySelector('iframe');
  const oEmbedData = iframe?.getAttribute('data-oembed');
  return oEmbedData ? JSON.parse(oEmbedData) : null;
};

export const Video = ({
  embedCode,
  title,
  portrait,
  renderPlaceholder,
}: {
  embedCode: string;
  title: string;
  portrait?: boolean;
  renderPlaceholder?: (playVideo: () => void) => ReactNode;
}) => {
  const [playVideo, setPlayVideo] = useState(false);
  const [oEmbedData, setOEmbedData] = useState<thumbnailData | null | undefined>(null);
  const { t } = useTranslation();

  useEffect(() => {
    const thumbnail: thumbnailData | null | undefined = parseOEmbed(embedCode || '');
    setOEmbedData(thumbnail);
  }, [embedCode]);
  const hasTrackedPlay = useRef(false);
  const id = useId();

  useEffect(() => {
    if (!document.getElementById('youtube_iframe_api')) {
      const tag = document.createElement('script');
      tag.id = 'youtube_iframe_api';
      tag.src = 'https://www.youtube.com/iframe_api';

      document.body.appendChild(tag);
    }
    if (!playVideo) return;

    let clearProgressInterval = () => {}; // eslint-disable-line @typescript-eslint/no-empty-function

    const createPlayer = () => {
      const player = new (window as any).YT.Player(id, {
        events: {
          onStateChange: (e: { data: number }) => {
            if (e.data === 0) {
              track({
                event: 'video_complete',
                traits: {
                  event_info: {
                    video_id: player.playerInfo?.videoData?.video_id,
                    video_length: player.getDuration(),
                    video_title: title ?? '',
                  },
                },
              });
            }
            if (hasTrackedPlay.current || e.data !== 1) return;

            let lastProgressPercentage = 0;

            const progressInterval = setInterval(() => {
              const progressPercentage =
                (100 * (player?.getCurrentTime() ?? 0)) / (player?.getDuration() ?? 1);
              const triggerPercentages = [90, 75, 50, 25];
              const triggeredPercentage = triggerPercentages.find(
                percentage =>
                  progressPercentage > percentage && lastProgressPercentage <= percentage,
              );
              if (triggeredPercentage) {
                track({
                  event: 'video_progress',
                  traits: {
                    event_info: {
                      video_id: player.playerInfo?.videoData?.video_id,
                      video_length: player.getDuration(),
                      progress_percent: triggeredPercentage,
                      video_title: title ?? '',
                    },
                  },
                });
              }
              lastProgressPercentage = progressPercentage;
            }, 500);

            clearProgressInterval = () => window.clearInterval(progressInterval);
            hasTrackedPlay.current = true;
            track({
              event: 'video_start',
              traits: {
                event_info: {
                  video_id: player.playerInfo?.videoData?.video_id,
                  video_length: player.getDuration(),
                  video_title: title ?? '',
                },
              },
            });
          },
        },
      });
    };

    if ((window as any).YT) {
      createPlayer();
    } else {
      (window as any).onYouTubeIframeAPIReady = createPlayer;
    }
    return clearProgressInterval;
  }, [title, id, playVideo]);

  return (
    <Wrapper>
      {playVideo ? (
        <StyledVideo
          $portrait={portrait}
          // Not using lazy loading and therefore replacing data-src with src.
          // Also added autoplay and mute to src-url so video plays directly when the play-button is clicked.
          dangerouslySetInnerHTML={{
            __html:
              embedCode
                ?.replace('data-src', 'src')
                .replace('<iframe ', `<iframe id="${id}" `)
                .replace('?', `?autoplay=1&origin=${window.location.origin}&`) || '',
          }}
        />
      ) : renderPlaceholder ? (
        renderPlaceholder(() => setPlayVideo(true))
      ) : (
        <ThumbnailContainer $portrait={portrait}>
          <Thumbnail
            $portrait={portrait}
            src={
              oEmbedData?.thumbnail_url ||
              'data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='
            }
            onClick={() => {
              setPlayVideo(true);
            }}
            alt=""
          />
          <PlayButton
            variant="primary"
            onClick={() => {
              setPlayVideo(true);
            }}
          >
            {t('marketing_play_video')}
          </PlayButton>
        </ThumbnailContainer>
      )}
    </Wrapper>
  );
};
