import styled, { css } from 'styled-components';
import type { LogosFragment } from '../../../wp-generated/types';
import { chooseLightOrDarkTextColor, moduleBackgroundToCSS } from '../getModuleColors';

const Container = styled.section<{ $background: string; $color: string }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: min(var(--spacing-large), var(--section-padding)) var(--site-padding);
  gap: var(--spacing-large);
  background: ${({ $background }) => $background};
  color: ${({ $color }) => $color};
`;

const Text = styled.h2`
  max-width: 800px;
  font-weight: var(--font-weight-extra-bold);
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

const Logos = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 150px));
  justify-content: center;
  gap: 1rem;
`;

const ImageContainer = styled.figure`
  width: 100%;
  max-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
`;

const StyledImg = styled.img<{ $invert?: boolean }>`
  max-width: 100%;
  height: 100%;
  ${({ $invert }) =>
    $invert &&
    css`
      filter: invert(100%) brightness(200%);
    `}
`;

const LogosModule = ({ moduleData }: { moduleData: LogosFragment }) => {
  return (
    <Container
      $background={moduleBackgroundToCSS(moduleData.bgColor)}
      $color={chooseLightOrDarkTextColor(moduleData?.bgColor)}
    >
      <Text>{moduleData.title}</Text>
      <Logos>
        {moduleData.images?.nodes.map(logo => {
          return (
            logo.mediaItemUrl && (
              <ImageContainer key={logo.id}>
                <StyledImg
                  src={logo.mediaItemUrl || ''}
                  alt=""
                  width={logo?.mediaDetails?.width || undefined}
                  height={logo?.mediaDetails?.height || undefined}
                  $invert={
                    !moduleData.bgColor?.find(
                      color => color === 'white' || color === 'transparent' || color === 'gray',
                    )
                  }
                />
              </ImageContainer>
            )
          );
        })}
      </Logos>
    </Container>
  );
};

export default LogosModule;
