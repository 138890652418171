'use client';
import dynamic from 'next/dynamic';
import { usePathname } from 'next/navigation';
import React, { useState } from 'react';
import { useSetLanguageLinks } from '../contexts/LanguageLinksContext';
import { shouldIgnoreMenuRender } from '../lib/menus';
import type {
  CustomerCasePageFragment,
  GlobalModulesFragment,
  PageFragment,
  PostPageFragment,
} from '../wp-generated/types';
import GlobalModule from './modules/GlobalModule';
import HeroSelector from './modules/HeroModule';
import Notice from './modules/Notice';

interface CmsPageAppRouterProps {
  locale: string;
  page: PageFragment | CustomerCasePageFragment | PostPageFragment | null;
}

const TextBlocksModule = dynamic(() => import('./modules/TextBlocksModule'));
const LogosModule = dynamic(() => import('./modules/LogosModule'));
const TestimonialsModule = dynamic(() => import('./modules/TestimonialsModule'));
const NumbersModule = dynamic(() => import('./modules/NumbersModule'));
const LatestPostsModule = dynamic(() => import('./modules/LatestPostsModule'));
const EmployeesModule = dynamic(() => import('./modules/EmployeesModule'));
const FeaturedPages = dynamic(() =>
  import('./modules/FeaturedModule').then(module => ({ default: module.FeaturedPages })),
);
const FeaturedPosts = dynamic(() =>
  import('./modules/FeaturedModule').then(module => ({ default: module.FeaturedPosts })),
);
const Reviews = dynamic(() => import('./modules/ReviewsModule'));
const Faq = dynamic(() => import('./modules/FaqModule/FaqModule'));
const PricingPlans = dynamic(() => import('./modules/PricingPlansModule/PricingPlansModule'));
const ComparisonTable = dynamic(
  () => import('./modules/ComparisonTableModule/ComparisonTableModule'),
);
const Video = dynamic(() => import('./modules/VideoModule'));
const CustomerCasesModule = dynamic(() => import('./modules/CustomerCasesModule'));
const WaveModule = dynamic(() => import('./modules/WaveModule'));
const LatestResources = dynamic(() => import('./modules/LatestResources'));
const TrialSignupModule = dynamic(() => import('./modules/TrialSignupModule'));
const RelatedPosts = dynamic(() => import('./modules/RelatedPosts'));
const IframeModule = dynamic(() => import('./modules/IframeModule'));
const PageAnchor = dynamic(() => import('./modules/PageAnchor'));
const PrReportIntro = dynamic(
  () => import('../app/[locale]/[type]/[[...pages]]/pr-report/components/intro'),
);
const ChapterOne = dynamic(
  () => import('../app/[locale]/[type]/[[...pages]]/pr-report/components/chapter-one'),
);
const ChapterTwo = dynamic(
  () => import('../app/[locale]/[type]/[[...pages]]/pr-report/components/chapter-two'),
);
const ChapterThree = dynamic(
  () => import('../app/[locale]/[type]/[[...pages]]/pr-report/components/chapter-three'),
);
const ChapterFour = dynamic(
  () => import('../app/[locale]/[type]/[[...pages]]/pr-report/components/chapter-four'),
);
const ChapterFive = dynamic(
  () => import('../app/[locale]/[type]/[[...pages]]/pr-report/components/chapter-five'),
);
const PrReportSummary = dynamic(
  () => import('../app/[locale]/[type]/[[...pages]]/pr-report/components/summary'),
);
const MissingModule = dynamic(() => import('./modules/MissingModule'));
const BreadcrumbsComponent = dynamic(() => import('./Breadcrumbs'), {
  loading: () => <p>Loading...</p>,
});
const ArchiveItemPage = dynamic(() => import('./ArchiveItemPage'));

export function getModule(
  moduleData:
    | NonNullable<NonNullable<PageFragment['modules']>['flexibleModules']>[0]
    | NonNullable<GlobalModulesFragment['data']>[0],
  locale?: string,
  hero?: PageFragment['hero'],
) {
  if (moduleData?.__typename === 'ModulesFlexibleModulesTextBlocksLayout') {
    return <TextBlocksModule moduleData={moduleData} />;
  } else if (moduleData?.__typename === 'ModulesFlexibleModulesLogosLayout') {
    return <LogosModule moduleData={moduleData} />;
  } else if (moduleData?.__typename === 'ModulesFlexibleModulesTestimonialsLayout') {
    return <TestimonialsModule moduleData={moduleData} />;
  } else if (moduleData?.__typename === 'ModulesFlexibleModulesNumbersLayout') {
    return <NumbersModule moduleData={moduleData} />;
  } else if (moduleData?.__typename === 'ModulesFlexibleModulesLatestPostsLayout') {
    return <LatestPostsModule moduleData={moduleData} />;
  } else if (moduleData?.__typename === 'ModulesFlexibleModulesEmployeesLayout') {
    return <EmployeesModule moduleData={moduleData} />;
  } else if (moduleData?.__typename === 'ModulesFlexibleModulesFeaturedPagesLayout') {
    return <FeaturedPages moduleData={moduleData} />;
  } else if (moduleData?.__typename === 'ModulesFlexibleModulesFeaturedPostsLayout') {
    return <FeaturedPosts moduleData={moduleData} />;
  } else if (moduleData?.__typename === 'ModulesFlexibleModulesReviewsLayout') {
    return <Reviews moduleData={moduleData} locale={locale} />;
  } else if (moduleData?.__typename === 'ModulesFlexibleModulesFaqLayout') {
    return <Faq moduleData={moduleData} />;
  } else if (moduleData?.__typename === 'ModulesFlexibleModulesPricingPlansLayout') {
    return <PricingPlans moduleData={moduleData} />;
  } else if (moduleData?.__typename === 'ModulesFlexibleModulesComparisonTableLayout') {
    return <ComparisonTable moduleData={moduleData} />;
  } else if (moduleData?.__typename === 'ModulesFlexibleModulesVideoLayout') {
    return <Video moduleData={moduleData} />;
  } else if (moduleData?.__typename === 'ModulesFlexibleModulesFeaturedCustomerCasesLayout') {
    return <CustomerCasesModule moduleData={moduleData} />;
  } else if (moduleData?.__typename === 'ModulesFlexibleModulesWaveLayout') {
    return <WaveModule moduleData={moduleData} />;
  } else if (moduleData?.__typename === 'ModulesFlexibleModulesLatestResourcesLayout') {
    return <LatestResources moduleData={moduleData} />;
  } else if (moduleData?.__typename === 'ModulesFlexibleModulesTrialSignupFormLayout') {
    return <TrialSignupModule moduleData={moduleData} />;
  } else if (moduleData?.__typename === 'ModulesFlexibleModulesRelatedPostsLayout') {
    return <RelatedPosts moduleData={moduleData} />;
  } else if (moduleData?.__typename === 'ModulesFlexibleModulesIframeLayout') {
    return <IframeModule moduleData={moduleData} />;
  } else if (moduleData?.__typename === 'ModulesFlexibleModulesPageAnchorLayout') {
    return <PageAnchor moduleData={moduleData} />;
  } else if (moduleData?.__typename === 'ModulesFlexibleModulesPrReportIntroLayout') {
    return <PrReportIntro moduleData={moduleData} hero={hero?.heroPrReport} locale={locale} />;
  } else if (moduleData?.__typename === 'ModulesFlexibleModulesPrReportChapterOneLayout') {
    return <ChapterOne moduleData={moduleData} locale={locale} />;
  } else if (moduleData?.__typename === 'ModulesFlexibleModulesPrReportChapterTwoLayout') {
    return <ChapterTwo moduleData={moduleData} locale={locale} />;
  } else if (moduleData?.__typename === 'ModulesFlexibleModulesPrReportChapterThreeLayout') {
    return <ChapterThree moduleData={moduleData} locale={locale} />;
  } else if (moduleData?.__typename === 'ModulesFlexibleModulesPrReportChapterFourLayout') {
    return <ChapterFour moduleData={moduleData} locale={locale} />;
  } else if (moduleData?.__typename === 'ModulesFlexibleModulesPrReportChapterFiveLayout') {
    return <ChapterFive moduleData={moduleData} locale={locale} />;
  } else if (moduleData?.__typename === 'ModulesFlexibleModulesPrReportSummaryLayout') {
    return <PrReportSummary moduleData={moduleData} locale={locale} />;
  } else {
    return <MissingModule typename={moduleData?.__typename ?? 'unknown'} details={moduleData} />;
  }
}

export default function CmsPageAppRouter({ locale, page }: CmsPageAppRouterProps) {
  const pathname = usePathname();
  const [noticeIsOpen, setNoticeIsOpen] = useState(true);
  const isFrontPage = page && 'isFrontPage' in page && page.isFrontPage;
  useSetLanguageLinks(isFrontPage ? [] : page && 'translations' in page ? page.translations : []);
  if (page === null) {
    return null;
  }
  return (
    <main>
      {page.__typename === 'Page' ? (
        <>
          {(page.seo?.breadcrumbs?.length || 0) > 1 &&
            !isFrontPage &&
            !shouldIgnoreMenuRender(pathname) && (
              <BreadcrumbsComponent
                breadcrumbs={
                  (page?.seo?.breadcrumbs?.filter(b => b?.url && b?.text) as {
                    url: string;
                    text: string;
                  }[]) ?? []
                }
                hero={page.hero}
              />
            )}
          {page.stickyModules?.notice?.title && noticeIsOpen && (
            <Notice
              notice={page.stickyModules?.notice}
              noticeIsOpen={noticeIsOpen}
              setNoticeIsOpen={setNoticeIsOpen}
            />
          )}
          <HeroSelector hero={page.hero} pageName={page.title ?? ''} />
          {page.modules?.flexibleModules?.map((moduleData, index) => {
            if (moduleData?.__typename === 'ModulesFlexibleModulesGlobalModuleLayout') {
              return <GlobalModule key={index} moduleData={moduleData} locale={locale} />;
            } else if (moduleData?.__typename === 'ModulesFlexibleModulesTextBlockLayout') {
              return <TextBlocksModule key={index} moduleData={moduleData} />;
            } else {
              return (
                <React.Fragment key={index}>
                  {getModule(moduleData, locale, page.hero)}
                </React.Fragment>
              );
            }
          })}
        </>
      ) : page.__typename === 'CustomerCase' || page.__typename === 'Post' ? (
        <ArchiveItemPage locale={locale} page={page} />
      ) : null}
    </main>
  );
}
